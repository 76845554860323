import { ConfigQuotaTicketPresenter } from "@/app/ui/presenters/ConfigQuotaTicketPresenter";
import { ConfigQuotaTicketMapper } from "@/data/persistences/mappers/ConfigQuotaTicketMapper";
import { container } from "tsyringe";
import { ConfigQuotaTicketEndpoint } from "../../endpoints/horde/ConfigQuotaTicketEndpoint";
import { ConfigQuotaTicketApiRepository } from "../../repositories/api/ConfigQuotaTicketApiRepository";
import ApiService from "../../services/ApiService";

export class ConfigQuotaTicketComponent {
  public static init() {
    container.register<ConfigQuotaTicketApiRepository>(
      ConfigQuotaTicketApiRepository,
      {
        useFactory: d => {
          return new ConfigQuotaTicketApiRepository(
            d.resolve(ApiService),
            d.resolve(ConfigQuotaTicketMapper),
            d.resolve(ConfigQuotaTicketEndpoint)
          );
        }
      }
    );
    container.register<ConfigQuotaTicketMapper>(ConfigQuotaTicketMapper, {
      useClass: ConfigQuotaTicketMapper
    });
    container.register<ConfigQuotaTicketPresenter>(ConfigQuotaTicketPresenter, {
      useFactory: d => {
        return new ConfigQuotaTicketPresenter(
          d.resolve(ConfigQuotaTicketApiRepository)
        );
      }
    });
  }
}
