
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import statusListData from "@/app/infrastructures/misc/common-library/StatusListData";
import { Vue } from "vue-class-component";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ConfigQuotaTicketController } from "@/app/ui/controllers/ConfigQuotaTicketController";
import { ApiRequestList, OptionsClass } from "@/domain/entities/MainApp";
import { ConfigQuotaTicketDetail } from "@/domain/entities/ConfigQuotaTicket";
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";
import { FlagsPermissionConfigQuotaTicket } from "@/feature-flags/flags-permission-config-quota-ticket";

export default class List extends Vue {
  mounted() {
    this.fetchList();
  }
  get title(): string {
    return (this.$route as any)?.meta?.title;
  }

  params = {
    status: new OptionsClass()
  };

  get statusData(): OptionsClass[] {
    return statusListData(["active", "inactive"]);
  }
  onSelectStatus(): void {
    this.listData.pagination.page = 1;
    this.fetchList();
  }

  listData: ResponsePayloadV2 = new ResponsePayloadV2();
  async fetchList() {
    this.listData.loading = true;
    try {
      this.listData = await ConfigQuotaTicketController.getList(
        new ApiRequestList({
          page: this.listData.pagination.page,
          limit: this.listData.pagination.limit,
          status: this.params.status.value
        })
      );
    } catch (err) {
      this.listData.message = parsingErrorResponse(err).type;
    } finally {
      this.listData.loading = false;
    }
  }

  columns = [
    {
      name: "ID",
      styleHead: "w-3/20 text-left",
      render: (item: ConfigQuotaTicketDetail) =>
        `<span class='text-left text-black-lp-300 flex'>${item.dbtcId}</span>`
    },
    {
      name: "Nama Konfigurasi",
      styleHead: "w-3/20 text-left",
      render: (item: ConfigQuotaTicketDetail) =>
        `<span class='text-left text-black-lp-300 flex'>${item.dbtcName}</span>`
    },
    {
      name: "Deskripsi",
      styleHead: "w-1/5 text-left",
      render: (item: ConfigQuotaTicketDetail) =>
        `<span class='text-left text-black-lp-300 flex'>${item.dbtcDescription}</span>`
    },
    {
      name: "Tanggal Dibuat",
      styleHead: "w-1/5 text-left",
      render: (item: ConfigQuotaTicketDetail) =>
        `<span class='text-black-lp-300 flex'>${formatDate(
          item.dbtcCreatedAt
        )}</span>
        <div class="capitalize text-gray-lp-500">${item.dbtcCreatedBy}</div>`
    },
    {
      name: "Tanggal Diperbarui",
      styleHead: "w-1/5 text-left",
      render: (item: ConfigQuotaTicketDetail) =>
        `<span class='text-black-lp-300 flex'>${formatDate(
          item.dbtcUpdatedAt
        )}</span>
        <div class="capitalize text-gray-lp-500">${item.dbtcUpdatedBy}</div>`
    },
    {
      name: "Status",
      styleHead: "w-2/20 text-left text-black-lp-300",
      render: (item: ConfigQuotaTicketDetail) =>
        ChipsStyles({ status: item.dbtcStatus })
    }
  ];

  onClickRow(item: ConfigQuotaTicketDetail) {
    this.$router.push(`/customer-service/config-quota-ticket/${item.dbtcId}`);
  }

  get isDetailAble() {
    return FlagsPermissionConfigQuotaTicket.permission_config_quota_ticket_enable.isEnabled();
  }
}
