import { injectable } from "tsyringe";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ConfigQuotaTicketApiRepository } from "@/app/infrastructures/repositories/api/ConfigQuotaTicketApiRepository";
import { ConfigQuotaTicketRequestInterface } from "@/data/payload/contracts/ConfigQuotaTicketRequest";
import { ConfigQuotaTicketEditAccountApiRequest } from "@/data/payload/api/ConfigQuotaTicketApiRequest";

@injectable()
export class ConfigQuotaTicketPresenter {
  private repository: ConfigQuotaTicketApiRepository;

  constructor(repository: ConfigQuotaTicketApiRepository) {
    this.repository = repository;
  }

  public getList(params: ApiRequestList): Promise<ResponsePayloadV2> {
    return this.repository.getList(params);
  }

  public getDetail(id: string): Promise<ResponsePayloadV2> {
    return this.repository.getDetail(id);
  }

  public editConfig(
    payload: ConfigQuotaTicketRequestInterface
  ): Promise<ResponsePayloadV2> {
    return this.repository.editConfig(payload);
  }

  public editAccount(
    payload: ConfigQuotaTicketEditAccountApiRequest
  ): Promise<ResponsePayloadV2> {
    return this.repository.editAccount(payload);
  }
}
