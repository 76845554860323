import { ApiRequestList } from "@/domain/entities/MainApp";

export class ConfigQuotaTicketEndpoint {
  // Landing page
  getList(params: ApiRequestList): string {
    return `horde/v1/dex-bucket-ticket-configuration?${params.toQueryString()}`;
  }
  getDetail(id: string): string {
    return `horde/v1/dex-bucket-ticket-configuration/${id}`;
  }
  editAccount(accountId: number): string {
    return `horde/v1/dex-bucket-ticket-account/${accountId}`;
  }
}
