import { ConfigQuotaTicketMapper } from "@/data/persistences/mappers/ConfigQuotaTicketMapper";
import { ConfigQuotaTicketEndpoint } from "../../endpoints/horde/ConfigQuotaTicketEndpoint";
import ApiService from "../../services/ApiService";
import { ConfigQuotaTicketRepositoryInterface } from "@/data/persistences/repositories/contracts/ConfigQuotaTicketRepositoryInterface";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ConfigQuotaTicketRequestInterface } from "@/data/payload/contracts/ConfigQuotaTicketRequest";
import {
  ConfigQuotaTicketEditAccountApiRequest,
  ConfigQuotaTicketEditConfigApiRequest
} from "@/data/payload/api/ConfigQuotaTicketApiRequest";

export class ConfigQuotaTicketApiRepository
  implements ConfigQuotaTicketRepositoryInterface {
  private service: ApiService;
  private mapper: ConfigQuotaTicketMapper;
  private endpoints: ConfigQuotaTicketEndpoint;

  constructor(
    service: ApiService,
    mapper: ConfigQuotaTicketMapper,
    endpoints: ConfigQuotaTicketEndpoint
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getList(params: ApiRequestList): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getList(params),
      {}
    );
    return this.mapper.convertListDataFromApi(resp);
  }

  public async getDetail(id: string): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getDetail(id),
      {}
    );
    return this.mapper.convertDetailDataFromApi(resp);
  }

  public async editConfig(
    payload: ConfigQuotaTicketRequestInterface
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "PATCH",
      this.endpoints.getDetail(
        (payload as ConfigQuotaTicketEditConfigApiRequest).payload.dbtcId
      ),
      undefined,
      payload as ConfigQuotaTicketEditConfigApiRequest
    );
    return this.mapper.convertFormDataFromApi(resp);
  }

  public async editAccount(
    payload: ConfigQuotaTicketRequestInterface
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "PATCH",
      this.endpoints.editAccount(
        (payload as ConfigQuotaTicketEditAccountApiRequest).accountId
      ),
      undefined,
      payload as ConfigQuotaTicketEditAccountApiRequest
    );
    return this.mapper.convertFormDataFromApi(resp);
  }
}
