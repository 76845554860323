/* eslint-disable @typescript-eslint/camelcase */
import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import { ConfigQuotaTicketDetail } from "@/domain/entities/ConfigQuotaTicket";
import { ConfigQuotaTicketRequestInterface } from "../contracts/ConfigQuotaTicketRequest";
import { QuotaTicketAccount } from "@/domain/entities/User";

export class ConfigQuotaTicketEditConfigApiRequest
  implements ConfigQuotaTicketRequestInterface {
  payload = new ConfigQuotaTicketDetail();
  constructor(fields?: Partial<ConfigQuotaTicketEditConfigApiRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase(this.payload));
  }
}

export class ConfigQuotaTicketEditAccountApiRequest
  implements ConfigQuotaTicketRequestInterface {
  accountId = 0;
  payload = new QuotaTicketAccount();
  constructor(fields?: Partial<ConfigQuotaTicketEditAccountApiRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase(this.payload));
  }
}
