import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import { PaginationV2 } from "@/domain/entities/Pagination";
import { ConfigQuotaTicketDetail } from "@/domain/entities/ConfigQuotaTicket";

export class ConfigQuotaTicketMapper {
  public convertListDataFromApi(result: AxiosResponse<any>): ResponsePayloadV2 {
    const { data } = result;

    return new ResponsePayloadV2({
      success: data.success,
      message: data.message,
      pagination: new PaginationV2({
        page: data.meta.page,
        limit: data.meta.limit,
        totalData: data.meta.total_records
      }),
      data: data.data.length
        ? data.data.map(
            (item: any) =>
              new ConfigQuotaTicketDetail({
                dbtcId: item.dbtc_id,
                dbtcName: item.dbtc_name,
                dbtcDescription: item.dbtc_description,
                dbtcCreatedAt: item.dbtc_created_at,
                dbtcCreatedBy: item.dbtc_created_by,
                dbtcUpdatedAt: item.dbtc_updated_at,
                dbtcUpdatedBy: item.dbtc_updated_by,
                dbtcStatus: item.dbtc_status
              })
          )
        : []
    });
  }

  public convertDetailDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = result;

    return new ResponsePayloadV2({
      data: new ConfigQuotaTicketDetail({
        dbtcId: data.dbtc_id,
        dbtcName: data.dbtc_name,
        dbtcDescription: data.dbtc_description,
        dbtcCreatedAt: data.dbtc_created_at,
        dbtcCreatedBy: data.dbtc_created_by,
        dbtcUpdatedAt: data.dbtc_updated_at,
        dbtcUpdatedBy: data.dbtc_updated_by,
        dbtcStatus: data.dbtc_status,
        dbtcStatusData: !!new RegExp(/^active$/gi).exec(data.dbtc_status)
      })
    });
  }

  public convertFormDataFromApi(result: AxiosResponse<any>): ResponsePayloadV2 {
    const {
      data: { data }
    } = result;

    return new ResponsePayloadV2({ data });
  }
}
