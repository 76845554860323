import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import { UserData } from "./User";

export class ConfigQuotaTicketDetail {
  dbtcId = "";
  dbtcName = "";
  dbtcDescription = "";
  dbtcCreatedAt = "";
  dbtcCreatedBy = "";
  dbtcUpdatedAt = "";
  dbtcUpdatedBy = "";
  dbtcStatus = "";
  dbtcStatusData = false;
  userList: UserData[] = [];

  constructor(fields?: Partial<ConfigQuotaTicketDetail>) {
    Object.assign(this, fields);
  }

  get formatCreatedAt() {
    return formatDate(this.dbtcCreatedAt);
  }

  get formatUpdatedAt() {
    return formatDate(this.dbtcUpdatedAt);
  }
}
