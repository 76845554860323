/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import { VuexModule, Module, Action, getModule } from "vuex-module-decorators";
import store from "@/store";
import { ConfigQuotaTicketPresenter } from "../presenters/ConfigQuotaTicketPresenter";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ConfigQuotaTicketComponent } from "@/app/infrastructures/dependencies/modules/ConfigQuotaTicketComponent";
import {
  ConfigQuotaTicketEditAccountApiRequest,
  ConfigQuotaTicketEditConfigApiRequest
} from "@/data/payload/api/ConfigQuotaTicketApiRequest";
ConfigQuotaTicketComponent.init();

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "config-quote-ticket"
})
class ConfigQuotaTicketStore extends VuexModule {
  @Action
  public getList(params: ApiRequestList) {
    const presenter = container.resolve(ConfigQuotaTicketPresenter);
    return presenter.getList(params);
  }

  @Action
  public getDetail(id: string) {
    const presenter = container.resolve(ConfigQuotaTicketPresenter);
    return presenter.getDetail(id);
  }

  @Action
  public editConfig(payload: ConfigQuotaTicketEditConfigApiRequest) {
    const presenter = container.resolve(ConfigQuotaTicketPresenter);
    return presenter.editConfig(payload);
  }

  @Action
  public editAccount(payload: ConfigQuotaTicketEditAccountApiRequest) {
    const presenter = container.resolve(ConfigQuotaTicketPresenter);
    return presenter.editAccount(payload);
  }
}

export const ConfigQuotaTicketController = getModule(ConfigQuotaTicketStore);
